@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

* {
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;

}

h1,
h2,
h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

p {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-bottom: 0px;
}

span {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
}



body {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
}


.cursor-pointer {
  cursor: pointer;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #FFB171;
}


.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {

  border: 2-x solid #dc3545;
  box-shadow: none;
}

.header {
  background: #FFFFFF;
  box-shadow: 0px -1px 10px rgba(0, 0, 0, 0.19);
  height: 75px;
  padding: 10px;
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 999;
  padding-right: 25px;
  align-items: center;
}

.Layout {
  padding-top: 76px;
}

.side-nav {
  width: 300px;
  background-color: #F6F7FA;
  animation-name: example;
  animation-duration: 0.4s;
  animation-timing-function: ease-in-out;

}

@keyframes example {
  from {
    width: 0px;
  }

  to {
    width: 256px;
  }
}

.side-nav1 {
  display: none;

  /* width:100px;
  background-color: #F6F7FA; */
}

.sidebarhead {
  margin-top: 50px;
}

.sidebarhead h3 {
  margin-left: 35px;
}

.page-container {
  width: calc(100% - 300px);
  height: 92vh;
  overflow-y: scroll;
  background: #FBFBFB;

}

.page-container1 {
  width: calc(100% - 300px);

  /* width: calc(100% - 100px); */
  /* height: 92vh; */
  overflow-y: scroll;
  background: #fbfbfb;
  /* transition: 0.5s; */
}


.max-hight {
  width: 200px;
  height: 200px;
  border-radius: 10px;
}

.max-hight img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}



/* siderbar */
.side-menu li a {
  cursor: pointer;
  padding: 10px 32px;
  font-size: 18px;
  color: #8892a6;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.side-menu li ul {
  max-height: 0;
  overflow: hidden;
  transition: max-height .8s ease;
}

.submenu {
  max-height: 500px !important;

}


.downArrow {
  width: 12px;
  transform: rotate(0deg);
  transition: transform .5s ease;
}

.upArrow {
  transform: rotate(180deg);
}

.side-menu li a:hover,
.side-menu li a.active {
  color: #FFB171;

}

.side-menu li ul a.actives {
  color: #3f0a87;
  border-left: 2px solid orange;

}

.active {
  color: #3f0a87;
  border-left: 3px solid orange;
  padding-left: 30px !important;
}

.header {
  background: #fff;

}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropbtn::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

/* sidebar */


/* header */

.renewbuylogo {
  height: 50px;
  margin-left: 36px;
  margin-right: 140px;
}


.renewbuylogo img {
  height: 45px;
}

.profile h4 {
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.04em;
  color: #282828;
}

.profile h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #747577;
}

.mainWrapper {
  padding: 0 20px;

}

.imagelogo {
  width: 30px;
  height: 30px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 30%;
  margin-right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagelogo2 {
  width: 30px;
  height: 30px;

  border-radius: 30%;
  margin-right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagelogos {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 30%;
  margin-right: 23px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navIcon {
  display: flex;
  align-items: center;
}

.navIcon p {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #5A5A5A;
}

.subnavIcon {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.04em;
}

.subnavlist {
  padding: 5px 32px !important;
  margin: 0px !important;
}

.menuhead {
  padding: 53px 32px 10px;
}


/* home section css */

.overview {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.switchbutton {

  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 36px;

}

.switchbutton button {

  width: 77px;
  height: 36px;
  border: none;
  color: #535353;
  background-color: #FFFFFF;
  border-radius: 8px;
  font-size: 14px;


}

.switchbuttons {

  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  height: 36px;

}

.switchbuttons button {

  width: 77px;
  height: 36px;
  border: none;
  color: #535353;
  background-color: #FFFFFF;
  border-radius: 8px;
  font-size: 14px;


}



.switchactive {
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 8px;
  width: 77px;
  height: 36px;
  color: #fff !important;
}

.heart {

  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #45DEF4 0%, #61FE9A 100%);
  border-radius: 36.75px;

}

.heart img {
  width: 60%;
}

.overviewcard p {
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 18px;
  color: #535353;
}

.card-radius span {
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  letter-spacing: 0.04em;


}


.totaldownload {
  background: #5BF7AB;
  border-radius: 136.5px;
  height: 5px;
}

.totalshare {
  background: #45DFF0;
  border-radius: 136.5px;
  height: 5px;
}

.card-radius {
  border-radius: 24px;
  /* background: url("../image/Logos/wave.png"); */
  /* background-repeat: no-repeat; */
  /* background-position: right bottom; */
  /* background-size: cover; */
  position: relative;

}

.card-details {
  position: absolute;
  width: 100%;
  height: 100%;
}

.card-img img {
  width: 100%;
  opacity: 0.2;
  border-bottom-right-radius: 24px;
}

/* card */
.searchInput {
  position: relative;
}

.searchInput {
  background: #FFFFFF;
  opacity: 0.2;
  border: 1px solid #808191;
  border-radius: 23px;
  padding: 8px 50px 8px 20px;
  max-width: 380px;

}

.searchInput {
  position: relative;
}

.searchInput input {
  width: 100%;
  border: none;
  outline: none;

}

.searchInput::before {
  content: url("../image/icons/search.png");
  position: absolute;
  top: 27%;
  right: 8%;

}

.selecttype {
  background: #FFFFFF;
  opacity: 0.2;
  border: 1px solid #808191;
  border-radius: 23px;
  padding: 15px 50px 15px 20px;

}

.searchInput input::placeholder {
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #666666;
}

.commanbtn {
  border: 1px solid #FFB171;
  color: #FFB171;
  padding: 7px 13px;
  background: transparent;
  border-radius: 10px;
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  height: 36px;
  width: 70px
}

.collateral-details {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #666666;
}

.collateral-details span {

  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #282828;
}

.approvedbtn {
  /* background: #00B929; */
  border: 1px solid #00B929;
  border-radius: 73px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* width: 100px;
  height: 28px; */
  width: 100%;
  padding: 7px 25px;
  color: #00B929;
  background: none;

}

.approvedbtn:hover {
  background: #00B929;
  color: #fff;
}

.deactivebtn {
  border: 1px solid #F64141;
  border-radius: 73px;
  /* width: 100px;
  height: 28px; */
  padding: 7px 25px;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #F74141;
  background: transparent;
}

.deactivebtn:hover {
  background: #F74141;
  color: #fff;
}

.collateral {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  border-radius: 30px;
}

.collateralbody {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.declinebtn {
  border: 1px solid #F64141;
  border-radius: 73px;
  background-color: transparent;
  /* width: 100px;
  height: 28px; */
  padding: 7px 25px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #F74141;

}

.declinebtn:hover {
  background-color: #F74141;
  color: #ffff;
}

.decline {
  background: #F74141;
  border: 1px solid #F64141;
  border-radius: 73px;
  color: #fff;
  padding: 7px 70px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;

  display: flex;
  align-items: center;

  color: #FFFFFF;
}

.approved {
  border: 1px solid #00B929;
  border-radius: 73px;
  background-color: #00B929;
  /* width: 100px;
  height: 28px; */
  padding: 7px 25px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #fff;

}

.deactive {
  border: 1px solid #F64141;
  border-radius: 73px;
  background-color: transparent;
  padding: 7px 25px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  background: #F74141;
  color: #fff;

}


.reactivebtn {
  border: 1px solid #00B929;
  border-radius: 73px;
  background-color: transparent;
  padding: 7px 25px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #00B929;

}

.reactivebtn:hover {
  background-color: #00B929;
  color: #fff;
}

.testbtn {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #666666;
}

.viewbtn {
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  color: #666666;

}

.carsize {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #666666;

  opacity: 0.5;
  padding: 0px 25px;
}




/* / Tables / */
.primary-table-theme .head {
  background-color: #dee9f9 !important;
  color: #0040a1 !important;
  border: 1px solid #dee9f9 !important;
  font-size: 0.8rem;
  z-index: 9;
  text-align: left;
}

.table-theme-1 th {
  position: sticky;
  top: 0px;
  /* background-color: #EDF2FC!important; */
  color: #747577 !important;
  /* border: 1px solid #EDF2FC !important; */
  padding: 0.5rem;
  /* font-size: 0.8rem; */
  z-index: 9;
  text-align: center;
  border-bottom: 2px solid #EDF2FC !important;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  background-color: #fff;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #747577;
}

.table-theme-1 tr {
  box-shadow: none;
}

.table-theme-1 tr:nth-of-type(even) {
  background-color: #EDF2FC;
}

/* .table-theme-1 tr:hover {

  background-color: #EDF2FC !important;

} */

/* .table-theme-1 tr:hover{

} */

.table-theme-1 td {
  position: relative;
  border: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
  /* background-color: #fff !important; */
  padding: 1rem 0.5rem;
  font-size: 0.8rem;
  text-align: center;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #535353;


}

/* .table-theme-1 td::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  top: 5px;
  height: 1px;
  background-color: #f6f6f6;
}

.table-theme-1 td::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 5px;
  height: 1px;
  background-color: #f6f6f6;
} */

.custom-table .head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #dee9f9;
  color: #0040a1;
  font-size: 0.8rem;
  z-index: 5;
}

.custom-table .head li {
  padding: 1rem 2rem;
}

#manage-tab .head li:nth-child(1) {
  width: 30%;
}

#manage-tab .head li:nth-child(2) {
  width: 15%;
}

#manage-tab .head li:nth-child(3) {
  width: 15%;
}

#manage-tab .head li:nth-child(4) {
  width: 15%;
}

#manage-tab .head li:nth-child(5) {
  width: 25%;
}

.custom-table .body {
  padding-left: 1rem;
  padding-right: 1rem;
}

.custom-table .custom-row {
  display: flex;
  padding-top: 3rem;
  padding-bottom: 2rem;
  height: 480px;
  border-bottom: 1px solid #f6f6f6;
}

.custom-table .body .left {
  width: 30%;
  padding: 1rem;
}

.custom-table .body .right {
  width: 70%;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.custom-table .body .comment-section {
  height: 70%;
  overflow-y: auto;
}

.post-detail .header,
.post-polling .header {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  top: -9%;
  background-color: transparent;
  box-shadow: none;
  padding: 0 0.6rem;
  color: #0040a1;
  font-size: 0.9rem;
  height: auto;
  z-index: 2;
}

.post-detail .header img,
.post-polling .header img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  margin-right: 10px;
  border: 2px solid #fff;
}

.post-detail .header img.home,
.post-polling .header img.home {
  width: 55px;
  height: 55px;
}

.post-detail.without-text .footer {
  padding-top: 50px;
}

.post-detail .footer .title {
  padding-top: 0.5rem;
  color: #0040a1;
}

.post-detail .footer .text {
  font-size: 0.8rem;
  color: #50504f;
}

.post-polling.modal-view,
.post-detail.modal-view {
  display: flex;
  justify-content: space-between;
}

.post-polling.modal-view .content,
.post-detail.modal-view .content {
  width: 50%;
}

.post-polling.modal-view .footer,
.post-detail.modal-view .footer {
  width: 45%;
}

.post-polling.modal-view .footer h3,
.post-detail.modal-view .footer h3 {
  margin-bottom: 10px !important;
  border: 0 !important;
}

.polling {
  font-size: 0.8rem;
  color: #50504f;
  padding: 0.5rem;
  background-color: #fff;
  margin-top: 1rem;
  cursor: pointer;
  transition: 0.5s all ease;
  position: relative;
}

.polling span {
  position: relative;
  z-index: 9;
  color: #0040a1;
  font-weight: 600;
}

.polling .progress-bar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #dee9f9;
}

.polling .progress-text {
  position: absolute;
  top: 0;
  right: 10px;
  height: 100%;
  display: flex;
  align-items: center;
  color: #0040a1;
  font-weight: 600;
}

.custom-table .comment-lists {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: #50504f;
  border-top: 0.9px solid #f6f6f6;
  border-bottom: 0.9px solid #f6f6f6;
  padding: 0.3rem 0;
  margin-bottom: 0.5rem;
}

.custom-table .comment-lists li {
  display: flex;
  align-items: center;
}

.custom-table .comment-lists li:nth-child(1) {
  width: 25%;
}

.custom-table .comment-lists li:nth-child(2) {
  width: 23%;
}

.custom-table .comment-lists li:nth-child(3) {
  width: 22%;
}

.custom-table .comment-lists li:nth-child(4) {
  width: 32%;
}

.custom-table .comment-lists li:nth-child(1) img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 10px;
}

.editbtn {

  color: #0066AF;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;


  letter-spacing: 0.04em;
  text-decoration-line: underline;
  cursor: pointer;
}

/* / Tables / */


/* rb store product  */

.productswitch {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-service {
  border-bottom: 2px solid #F0F4F8;
  width: 80%;
}

.product-servicex {
  border-bottom: 2px solid #f0f4f8;
  width: 90%;
  padding-left: 22px;
}

.merchandise-libary {

  cursor: pointer;
  margin-right: 70px;

}

.collaterals-lab {
  cursor: pointer;
}

.productactive {
  border-bottom: 4px solid #FFB171;
  border-radius: 2px 2px 0px 0px;
}

.colletaralfilterprduct ul {
  border-bottom: 1px solid #F0F4F8;
}

.colletaralfilterprduct ul li {
  margin: 0px 30px;
  padding: 10px;
  cursor: pointer;

}

.activeColletaral {
  border-bottom: 2px solid #FF943D;
}

.collateral-product-type span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #282828;
}

.collateral-product-type a {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: 0.04em;
  text-decoration-line: underline;

  /* qwet */

  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

}

.editbtncol {
  border: 1px solid #5989DE;
  border-radius: 73px;
  background-color: transparent;
  width: 100px;
  height: 28px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #5989DE;

}

.collateral-detailsprice {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;

}


/* rb store product */



/*--------------- profilepage------------ */

.profile_Avatar {
  width: 97px;
  height: 97px;
  background: #E3D5FF;
  border-radius: 50%;
}

.profile_avtar_details h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;

  color: #282828;
}

.profile_avtar_details p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: 0.04em;

  color: #808191;
  margin-top: 7px;
}

.profile_form_details p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-left: 10px;
  /* identical to box height */


  /* Primary Text - Black #282828 */

  color: #282828;
}

.profile_form_details input {
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24.2521px;
  padding: 0 20px;
  margin-top: 5px;
}

.profile_form_details input::placeholder {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

  color: #808191;
}

.profile_editbtn button {
  /* width: 290px; */
  height: 48px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 70px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #FFFFFF;

  transform: matrix(1, 0, 0, 1, 0, 0);
}

.passwordview {
  position: relative;
}

.passwordview img {
  position: absolute;
  top: 50%;
  right: 43%;
}

.viewpassword {
  cursor: pointer;
}

/* slider */

.swiper-button-disabled {
  opacity: 0 !important;
}

.swiper-button-prev:after {
  content: url("../image/home/next.png") !important;

  transform: rotate(180deg);
}


.swiper-button-next:after {
  content: url("../image/home/next.png") !important;

}

.barchart {
  width: 100%;
}

.chartdetails {
  width: 1360px;
  height: 300px;
}

.recharts-rectangle {
  width: 50px !important;
}

.switch-chart {
  border-bottom: 1px solid #f6f6f6;
}

.switch-image-btn {
  cursor: pointer;
}

.switch-video-btn {
  cursor: pointer;
}

.switch-active {
  border-bottom: 4px solid #FF943D;

}

.switch-chart-btn {
  border: 1px solid #F0F4F8;
  border-radius: 10px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  letter-spacing: 0.04em;


}

.switch-active-btn {
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 10px;
  color: #fff;

}

.excle-export {
  background: none;
  border-radius: 10px;
  border: 1px solid #FF943D;
  padding: 7px 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;
  color: #FF943D;
}

/* rb-store card analytics */
.analyticCardetails h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: 0.04em;

  color: #535353;
}

.analyticCardetails p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #535353;
}

.analyticCardetails p:last-child {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #535353;
}

.analityics-tabs {
  border-bottom: 1px solid #ECF2FA;
}

.analityics-tabs p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.04em;

  color: #747577;

}

.active-tab {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #535353;
  border-bottom: 4px solid #FF943D;
}

.switch-content {
  border-bottom: 1px solid #f6f6f6;

}

.switch-content h3 {
  cursor: pointer;

}


.active-content {
  border-bottom: 4px solid #FF943D;
  display: flex;
  align-items: center;
}

.card-image img {
  width: 100%;
}

.content-card {
  border-radius: 50px;
  position: relative;
  border: none;
}

.content-details {
  position: absolute;
  background: #FFFFFF;
  opacity: 0.9;
  border-radius: 0px 0px 30px 30px;
  bottom: 0;
  padding-bottom: 30px;
  width: 100%;

}



.recharts-text {
  font-size: 14px;
}

.content-details p {
  padding: 30px 30px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.04em;

  color: #282828;

}

/* Modal */
#custom-modal {
  margin: 0 auto;
  width: 100%;
  border: 0;
  outline: none;
  padding: 2rem 5rem 5rem;
  max-height: 90vh !important;
  /* overflow-y: scroll; */
  /* border-top: 5px solid #0040a1; */
  border-radius: 30px;
  background-color: #f6f6f6 !important;


}

#custom-modal .title {
  font-size: 1.2rem;
  color: #0040a1;
  font-weight: 600;
  width: auto;
  margin-bottom: 3rem;
  text-transform: uppercase;
  border-bottom: 2px solid #0040a1;
}

#custom-modal .label,
#custom-modal .radio-label span {
  font-size: 1rem;
  color: #0040a1;
  font-weight: 600;
  width: auto;
}

#custom-modal .radio-label {
  margin-right: 2rem;
}

#custom-modal .cross {
  position: relative;
  display: block;
  margin-left: auto;
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-bottom: 2rem;
  /* right: -3rem; */
  right: -4.8rem;
  top: -40px;
}

/* #custom-modal .cross::before {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -2px;
  right: 99px;
  position: absolute;
  transform: rotate(134deg) translate(2.5px, -0.5px);
  right: 8px;
}

#custom-modal .cross::after {
  content: " ";
  width: 2px;
  height: 100%;
  background-color: #000;
  top: -7px;
  right: 19px;
  position: absolute;
  transform: rotate(225deg) translate(-9.5px, 0px);
  right: 16px;

} */

#custom-modal .warning-modal .title {
  color: #cb0000;
  border-bottom: 2px solid #cb0000;
  text-align: center;
}

#custom-modal .warning-modal p {
  margin-bottom: 1rem;
  font-size: 0.9rem;
  text-align: center;
}

#custom-modal .warning-modal button {
  border-width: 1px !important;
  font-weight: 400 !important;
  padding: 0.3rem 2rem !important;
}

#custom-modal .warning-modal .approve_decline_padding {
  background-color: #cb0000;
  color: #fff !important;
}

#custom-modal .warning-modal .approve_decline_padding .cross-icon::before,
#custom-modal .warning-modal .approve_decline_padding .cross-icon::after {
  background-color: #fff;
}



/* Modal */



/* home modal */

.radio-btn {
  border: 2px solid #FF943D;
  width: 20px;
  height: 20px;
}

.custom-radio-btn span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;

  color: #000000;

  opacity: 0.5;
}

.custom-month button {
  width: 58px;
  height: 28px;


  border: 1px solid #808191;
  border-radius: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #808191;
}

.w-40 {
  width: 37% !important;
}

.w-45 {
  width: 45% !important;
}

.modal-row {
  opacity: 0.2;
  border: 1px solid #808191;
  transform: rotate(-0.15deg);
}

.custom-date input {

  width: 200px;
  height: 38px;
  border: 1px solid #808191;
  border-radius: 24px;
  padding: 10px;

}

/* home modal */


/* add-collateral */
.add-collateral-type h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;

  color: #000000;

  opacity: 0.5;
}

.add-collateral-type input {
  width: 100%;

  padding: 15px;

  background: #FFFFFF;
  opacity: 0.2;
  border: 1px solid #808191;
  border-radius: 23px;
}

.concel-btn-modal {
  width: 250px;
  height: 40px;


  /* grey cta */

  border: 1px solid #808191;
  border-radius: 29px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  padding: 10px;

  /* text fill */

  color: #ABABAB;
  background: none;

}

.save-btn-modal {

  width: 250px;
  height: 40px;
  /* qwet */
  margin-left: 10px;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 29px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #FFFFFF;
  border: none;


}

.delete-heading {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #000000;

  opacity: 0.5;
}

/* test modal */
.test-modal h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.04em;

  color: #282828;
}

.test-modal p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  color: #282828;
}

.test-modal input {
  width: 300px;
  height: 48px;
  padding: 15px;

  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24.2521px;
}

.test-modal-lang-btn button {
  width: 84px;
  height: 28px;

  border: 1px solid #808191;
  border-radius: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #808191;
  background: none;
}

.add-people-btn button {
  border: 1px solid #808191;
  border-radius: 24px;
  padding: 5px 17px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: none;

  letter-spacing: 0.04em;

  color: #808191;
}

/* test modal */



.inputDrop {
  border: 1.14828px dashed #9C9C9C;
  border-radius: 20.669px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  background: #f1f2f1;
  cursor: pointer;
}

.inputDrops {
  border: 1.14828px dashed #9C9C9C;
  border-radius: 20.669px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f2f1;
  cursor: pointer;
}

.imagePreview {
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border: 1px solid #f1f2f3;
  padding: 10px;
}

.previewImage {
  position: relative;
}

.previewImage img {
  /* width: 120px; */
  height: 200px;
  margin: 3px;
}

.removeImg {
  position: absolute;
  color: red;
  width: 15px;
  height: 20px;
  z-index: 100;
  right: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.Product-details p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  /* Primary Text - Black #282828 */

  color: #282828;
}

.Product-details input {
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24.2521px;
  width: 100%;
  height: 48px;
  padding: 15px;
}

.Product-details input::placeholder {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;

  color: #808191;
}

.Product-details textarea {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #808191;
  border-radius: 24.2521px;
  padding: 15px;
}

.Product-details textarea::placeholder {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #808191;
  opacity: 0.3;
}

.concel-btn-modal-profile {
  border: 1px solid #808191;
  border-radius: 281px;
  padding: 10px 38px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;

  /* text fill */

  color: #ABABAB;
}

.save-btn-modal-profile {
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 300px;
  padding: 10px 38px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #FFFFFF;
  border: none;
}

.addCollateralSwitch {
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #ECF2FA;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  /* line-height: 15px; */
  letter-spacing: 0.04em;
  color: #535353;
  cursor: pointer;
}

.activeCollateral {
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 10px;
  color: #fff;
}

/* from add */
.add-collateral-input input {
  width: 420px;
}


.collateral-category button {
  padding: 6px 17px;
  border: 1px solid #808191;
  border-radius: 24px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: none;
  letter-spacing: 0.04em;
  color: #808191;
  margin-right: 10px;

}

.collateral-description textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #808191;
  border-radius: 10px;
}

.collateral-Sehedule input {
  border: 1px solid #808191;
  border-radius: 24px;
  width: 194px;
  height: 28px;
  padding: 5px;
}

.collateral-language-adapts button {
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border: none;
}

.lab {
  border: 1px solid black !important;
}

#dropdown-basic-button {
  background: none;
  border: 1px solid #FF943D;
  color: #FF943D;
  border-radius: 10px;
  font-size: 14px;
  padding: 4px 13px;
}

/* filter modal */
.filter-modal p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.04em;
  color: #000000;
  opacity: 0.5;
}

.filter-btn button {
  color: #808191;
  background: none;
  border: 1px solid #808191;
  border-radius: 24px;
  padding: 4px 17px;

}

.border-bottom-filter {
  opacity: 0.2;
  border: 1px solid #808191;
}

.Insurer-btn {
  display: flex;
}

.Insurer-btn button {
  background: none;
  padding: 4px 25px;
  border-radius: 52px;
  margin-right: 30px;

}

.deletebtn {
  border: 1px solid #F74141;
  color: #F74141;
}

.edit {
  border: 1px solid #4070C5;
  color: #4070C5;
}

.concelbtn {
  width: 270px;
  height: 40px;

  /* grey cta */

  border: 1px solid #808191;
  border-radius: 70px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.04em;

  /* text fill */

  color: #ABABAB;

  transform: matrix(1, 0, 0, 1, 0, 0);
  background: none;
}




/* filter modal */


/* spiner */

.loadingSpinnerContainer {

  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #FF943D transparent #FF943D transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


/* spiner */


/* login screen */
.login-banner {
  background-image: url("../image/login/loginbanner.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  height: 95vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

}

.login-content {
  width: 80%;
}

.login-logo {
  width: 100%;
}

.login-title h3 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 44px;
  /* or 157% */

  text-align: center;
  letter-spacing: 0.04em;

  /* Primary Text - Black #282828 */

  color: #282828;

  mix-blend-mode: normal;
}

.login-title h3 span {
  font-weight: 500;
}

.login-form {
  width: 60%;
}

.rb-logo {
  position: absolute;
  top: 25px;
}

.form {
  height: 80vh;
}

.information {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.04em;

  color: #535353;

  opacity: 0.6;
  text-align: center;
}

.custom-form-control {
  background: #fff !important;
  padding: 15px 60px 15px 20px;
  border-radius: 50px;
}

.custom-form-control:focus {
  border-color: #FF943D;
  outline: 0;
  box-shadow: none !important
}

.passwordcustom {
  position: relative;
}

.password-see {
  position: absolute;
  right: 27px;
  top: 33px
}

.input-label {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;

  /* Primary Text - Black #282828 */

  color: #282828;
}

.input-label sup {
  color: #FF943D;
}

.submit-btn {
  width: 100%;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  border-radius: 50px;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.04em;
  padding: 15px 0px;
  color: #ffff;
}

.forget-password p {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;

  /* Primary - Orange #F27F21 */

  color: #F27F21;
}

.login-with-otp {
  background: #fff;
  width: 100%;
  border-radius: 50px;
  border: 1px solid #FF943D;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.04em;
  /* identical to box height */

  text-align: center;
  letter-spacing: 0.04em;
  padding: 15px 0px;
  color: #FF943D;
  background: linear-gradient(180deg, #FFB171 0%, #FF943D 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

}

.phone-btn {
  background: rgba(122, 153, 237, 0.1);
  border-radius: 11.9444px;
  border: none;
  padding: 8px;
}

/* login screen */

/* custom checkbox */

.ux-btn-group {
  outline: none;
  display: flex;
  flex-wrap: wrap;

}

.ux-btn-group li {
  outline: none;
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}

.ux-btn-group input {
  width: 0;
  height: 0;
  cursor: pointer;
}

.ux-btn-group input.ux-btn-group-item {
  opacity: 0;
  margin-left: 0px;

}

.ux-btn-group input.ux-btn-group-item:checked+label {
  background-image: linear-gradient(180deg, #FFB171 0%, #FF943D 100%) !important;
  outline: none;
  border: 1px solid #FF943D;
  color: #fff !important;
  cursor: pointer;
}

.ux-btn-group label {
  border: 1px solid #808191;
  border-radius: 24px;
  padding: 5px 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  background: none;
  letter-spacing: 0.04em;
  color: #808191;
  margin: 10px;
  cursor: pointer;
}

.ux-btn-group label:active {
  outline: none;
  border: 1px solid #FFB171;
  background-image: linear-gradient(180deg, #FFB171 0%, #FF943D 100%) !important;
  color: #fff !important;
}

.ux-btn-group label:focus {
  outline: none;
}

.activeManth {
  background-color: #FF943D;
  color: #fff !important;
}


/* custom checkbox */

/* decline details */
.decline-details {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #F74141;
}

.approve-details {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #00B929;
}

.Reactive-details {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;

  color: #00B929;
}


/* upload */

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.btn-upload {
  border: 2px dotted grey !important;
  font: normal normal normal 16px/24px SegoeRegular;
  letter-spacing: 0px;
  color: #FFFFFF !important;
  background: transparent linear-gradient(90deg, #EE5435 0%, #F17F3A 100%) 0% 0% no-repeat padding-box;



  width: 250px;
  height: 80px;
  padding: 0 5px;
  border-radius: 15px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.concelbutton {
  position: relative;
}

.concelcross {
  position: absolute;
  top: -8px;
  right: -2px;
}

.concelcross img {
  width: 20px;
  height: 20px;
}

.articledelbtn {
  padding: 5px 40px;
  opacity: 0.8;
  border: 1px solid #F64141;
  border-radius: 73px;
  background: transparent;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #F64141;
}

.article-edit {
  padding: 5px 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  opacity: 0.8;
  border-radius: 73px;
  background: transparent;
  border: 1px solid #4070C5;
  /* identical to box height */

  display: flex;
  align-items: center;

  /* b-g */

  background: linear-gradient(180deg, #5989DE 0%, #4070C5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

}

.card-image img {
  border-radius: 40px;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #535353 !important;
  font-weight: 600 !important;
}

.editorSectionStart {
  margin-top: 20px;
  border: 1px solid #BEC0C7;
  min-height: 15rem;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

.editorClassName {
  margin-left: 2rem;
  margin-right: 2rem;
}

.card-height {
  height: 300px;
}


.card-height img {
  height: 100%;
  object-fit: cover;
}

.overlayer {
  position: relative;
}

.overlayer img {
  filter: brightness(0.6);
}

.playicon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;


}


.playicon img {
  width: 30px;
  height: 30px;
  z-index: 9999;
  position: absolute;
  top: 40%;
  left: 40%;
  filter: blur();

}

.pagination button {
  background: none;
  border: none;
}

.dropdown-item:active {
  font-style: normal;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: var(--bs-dropdown-link-color);
  text-decoration: none;
  background: rgba(255, 177, 113, 0.1);
}

.dropdown-item:nth-of-type(even) {
  background: rgba(255, 177, 113, 0.1);
}

.activefilter {
  background-color: #F17F3A;
  color: #ffff;

}

.filter_details {
  position: relative;
}

.filter_cross {
  position: absolute;
  width: 20px;
  right: -10px;
  top: -8px;
}

.activeSort button {

  background-color: #F17F3A !important;
  color: #ffff !important;
}


/* width */
::-webkit-scrollbar {
  width: 0px;
}

.page-container::-webkit-scrollbar {
  width: 5px;
}



/* Track */
.page-container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.page-container::-webkit-scrollbar-thumb {
  background: #FF943D;
  border-radius: 10px;
}

/* Handle on hover */
.page-container::-webkit-scrollbar-thumb:hover {
  background: #FF943D;

}

.edit_btn_content_hub {
  background: none;
  border: 1px solid #FF943D;
  color: #FF943D;
  border-radius: 10px;
  font-size: 15px;
  padding: 4px 13px;
}

.Tags_Edit {
  width: 40%;
}

.tags-edits {
  justify-content: space-between;
  border-bottom: 1px solid #F0F4F8;
}

.content_tag {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;

  color: #747577;
}

.tags-edits h4 {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  letter-spacing: 0.04em;
}

.content-edit {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.04em;
  text-decoration-line: underline;
  /* blue */
  color: #0066AF;
}

.video_Response {
  max-width: 100%;
}

.video_Response video {
  height: 500px;
}

.drag-width {
  width: 300px;
}

.responsive-details {
  height: 100px;
}

.insurance-height {
  height: 150px;
}

.responsive-details {
  height: 150px;
  overflow: scroll;
}

.description-browser {
  height: 200px;
  overflow: scroll;
  max-width: 400px;
}

.logoimage {
  display: none;
}

.merchandise-libarye {
  cursor: pointer;
  margin-right: 17px;
}

.merchandise-libarye {
  cursor: pointer;
  margin-right: 70px;
}

.merchandise-libarye h4 {
  /* / width: 179px; / */
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #282828;
}

.collaterals-labe {
  cursor: pointer;
}

.collaterals-labe h4 {
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #282828;

}

.profiler {
  padding-left: 28px;
}

.product-service {
  border-bottom: 2px solid #f0f4f8;
  width: 90%;
  padding-left: 22px;
  padding-top: 0px;
}

.productactive {
  border-bottom: 4px solid #ffb171;
  border-radius: 2px 2px 0px 0px;
  padding-bottom: 1rem;
}

.merchandise-libary {
  cursor: pointer;
  margin-right: 70px;
}

.merchandise-libary h4 {
  /* / width: 179px; / */
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #282828;
}

.collaterals-lab {
  cursor: pointer;
}

.collaterals-lab h4 {
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 17px;
  letter-spacing: 0.04em;
  color: #282828;

}

.css-1aquho2-MuiTabs-indicator {
  position: absolute;
  height: 2px;
  bottom: 0;
  width: 100%;
  -webkit-transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #ff943d !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #373636 !important;
  min-width: 64px !important;

}

.css-1q2h7u5.Mui-selected {
  color: #373636 !important;
  min-width: 64px !important;

}

.css-ttwr4n {
  background-color: #ff943d !important;

}

.abcd {
  display: flex;
  align-items: center;
  justify-content: center;
  /* / height: 170px; / */
}

.sizeaddcoletral h3 {
  font-size: 16px;
}

.overflowhide {
  height: 137px;
  overflow: hidden;
}

/* / ============================media query responisve =================== // */

@media (max-width:1720px) {
  .test-modal input {
    width: 100%;
  }
}

@media(max-width:1480px) {
  .max-hight {
    width: 150px;
    height: 150px;
  }

  .approvedbtn {
    margin-right: 10px !important;
  }

  .renewpading {
    padding-left: 24px !important;
  }

  .collateralbody {
    padding-left: 20px !important;
  }

  .space-form {
    margin-left: 20px;
  }

  .filterbtn {
    display: block !important;
  }

  .save-btn-modal {
    /* / margin-top: 15px; / */
    margin-left: 10px;
    width: 100%;
  }

  .concel-btn-modal {
    width: 100%;
  }

  .wraprre {
    flex-wrap: wrap;
  }

  .selecttype {
    margin-top: 10px;
  }
}

@media(max-width:1350px) {
  .approved {
    padding: 8px 15px;
  }

  .declinebtn {
    padding: 8px 15px;
  }

  .deactivebtn {
    padding: 8px 15px;
  }

  .approvedbtn {
    padding: 8px 15px;
  }

  .Insurer-btn button {
    background: none;
    padding: 0px 9px;
    border-radius: 10px;
    margin-right: 11px;
    /* / font-size: 14px; / */


  }

  /* .profile_form_details input {
    max-width: 290px;
  } */

  .responsive-details {
    height: 100px;
  }
}

@media(max-width:1220px) {
  .side-nav {
    width: 256px;
  }

  .page-container {
    width: 100%;
    position: relative;
    /* / overflow-y: hidden; / */

  }

  .page-container1 {
    width: 100%;
    position: relative;
  }

  .collateralbody {
    padding: 2rem !important;
  }

  .approved {
    font-size: 10px;
    border-radius: 11px;
    padding: 8px 8px;
  }

  .declinebtn {
    border-radius: 11px;
    padding: 8px 8px;
  }

  .decline {
    padding: 7px 40px;
  }

  .deactive {
    border-radius: 11px;
    padding: 8px 8px;
  }

  .reactivebtn {
    border-radius: 11px;
    padding: 8px 8px;
  }

  .collateral-details-pending-responisve {
    padding-left: 2rem !important;
    padding-right: 0 !important;
    padding-top: 1rem !important;
  }

  .collateraldata {
    display: block !important;
  }

  /* #custom-modal {
    margin: 0 auto;
    width: 100%;
    border: 0;
    outline: none;
    padding: 0rem 2rem 2rem;
    max-height: 90vh !important;
    border-radius: 30px;
    background-color: #f6f6f6 !important;
}
  #custom-modal .cross {
    position: relative;
    display: block;
    margin-left: auto;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-bottom: 0rem;
    right: 0rem;
    top: -40px;
} */
}

@media(max-width:1125px) {
  .collateral-details-responisve {
    padding-left: 2rem !important;
    padding-top: 1rem !important;
  }

  .renewbuylogo {
    height: 42px;
    margin-left: 23px;
    margin-right: 80px;
  }

  .profile {
    padding-left: 0px;
  }

  .profiler {
    padding-left: 15px;
  }

  .samriti3 {
    width: 100%;
    height: 27px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.04em;
    color: #282828;
    padding-bottom: 4px;
  }

  .samriti5 {
    width: 100%;
    height: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #747577;
    padding-top: 4px;
  }

  .Tags_Edit {
    width: 60%;
  }
}

@media(max-width:1117px) {
  body {
    font-size: 14px;
  }

  .collateral-details {
    font-size: 9px;
  }
}

@media(max-width:1095px) {
  .activitymonitor {
    margin-right: 2rem !important;
    font-size: 18px;
  }

  #custom-modal {
    margin: 0 auto;
    width: 100%;
    border: 0;
    outline: none;
    padding: 1rem 2rem 3rem;
    max-height: 90vh !important;
    /* / overflow-y: scroll; / */
    /* / border-top: 5px solid #0040a1; / */
    border-radius: 30px;
    background-color: #f6f6f6 !important;
  }

  #custom-modal .cross {
    position: relative;
    display: block;
    margin-left: auto;
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-bottom: 2rem;
    /* / right: -3rem; / */
    right: -1.8rem;
    top: -27px;
  }
}

@media (max-width:1000px) {
  .merchandise-libarye h4 {
    /* / width: 179px; / */
    height: 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #282828;
  }

  .collaterals-labe h4 {
    /* / width: 225px; / */
    height: 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 17px;
    letter-spacing: 0.04em;
    color: #282828;
  }
}

@media (max-width:963px) {
  .logoimage {
    display: block;
    width: 35px;
    padding-right: 10px;
  }

  .side-nav {
    z-index: 1000;
    position: fixed;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    transition: all 0.5s ease;
  }

  .form-flex {
    display: block !important;
  }

  .space-form {
    margin-left: 0px;
  }

  .mg-space {
    margin-top: 15px;
  }

  .ux-btn-group {
    outline: none;
    display: flex;
    overflow-y: scroll;
    flex-wrap: inherit;

  }
}

@media screen and (max-width: 768px) {
  #custom-modal {
    margin: 0 auto;
    width: 83% !important;
  }

  .logoimage {
    display: block;
    width: 35px;
    padding-right: 10px;
  }

  .renewbuylogo img {
    height: 35px;
  }

  .profile {
    padding-left: 4px;
  }

  .profiler {
    padding-left: 5px;
  }

  .profile h3 {
    font-size: 22px;
  }

  .profile h5 {
    font-size: 14px;
  }

  .activemonter {
    margin-top: 10px;
  }

  .samriti3 {
    width: 100%;
    height: 27px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: 0.04em;
    color: #282828;
    padding-bottom: 4px;
  }

  .samriti5 {
    width: 100%;
    height: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.04em;
    color: #747577;
    padding-top: 4px;
  }

  .product-service {
    border-bottom: 2px solid #f0f4f8;
    width: 80%;
    padding-left: 22px;
  }

  /* .aprovalpending-row{
  flex-wrap: wrap;
} */
  .side-nav {
    z-index: 1000;
    position: fixed;
    height: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    transition: all 0.5s ease;
  }

  .masterflex {

    display: block !important;
    justify-content: center;
  }

  .masterpagination {
    margin-top: 20px;
    justify-content: end;
  }

  .appdecline {
    margin-right: 15px !important;
  }

  .merchandise-libarye h4 {
    width: 252px;
    font-size: 20px;
  }

  .collaterals-labe h4 {
    width: 220px;
    font-size: 20px;
  }
}

@media screen and (max-width:650px) {

  .profile h3 {
    font-size: 20px;
  }

  .profile h5 {
    font-size: 12px;
  }

  .Tags_Edit {
    width: 60%;
  }

  .collertalimgtyype {
    padding-left: 5px !important;
  }

  /* .switch-image-btn{
  margin-right: 0.25rem!important;
  padding-right: 1.2rem!important;
  padding-left: 1.2rem!important;
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
} */
  .exportexcel {
    justify-content: space-between !important;
  }

  .activemonter {
    justify-content: space-between !important;

  }

  .merchandise-libarye h4 {
    width: 222px;
    font-size: 17px;

  }

  .collaterals-labe h4 {
    width: 168px;
    font-size: 16px;
  }
}

@media screen and (max-width:560px) {
  .renewbuylogo {
    height: 42px;
    margin-left: 18px;
    margin-right: 60px;
  }

  .profile {
    padding-left: 0px;
  }

  .profile h3 {
    font-size: 18px;
  }

  .profile h5 {
    font-size: 11px;
  }

  .Brochurescompany {
    margin-right: 0px !important;
  }

  .dropbrocom {
    margin-right: 3px !important;
  }

  .productswitch {
    width: 100%;
    display: block !important;
    align-items: center;
    justify-content: space-between;
  }

  .addbtnmv {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 20px;
  }

  .product-service {
    border-bottom: 2px solid #f0f4f8;
    width: 100%;
    padding-left: 22px;
  }

  .declineflex {
    display: block !important;
  }

  .declinedpad {
    margin-top: 20px;
    justify-content: end !important;
  }

  .switch-chart-btn {
    font-size: 12px;
  }

  .switch-chart-btn {
    margin-top: 11px;
  }

  .switch-image-btn {
    padding-bottom: 0.6rem !important;
    padding-top: 0.6rem !important;
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
    margin-right: 0.25rem !important;
  }

  .switch-video-btn {
    padding-bottom: 0.6rem !important;
    padding-top: 0.6rem !important;
    padding-right: 1.2rem !important;
    padding-left: 1.2rem !important;
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .flexbasic h3 {
    padding-right: 0px !important;
    padding-left: 2px !important;
  }
}

@media screen and (max-width:500px) {
  .table-container {
    overflow-y: scroll;
  }

  .header {
    padding: 6px;
  }

  h1,
  h2,
  h3 {
    font-size: 21px;
  }

  .active-content h3 {
    padding-right: 0px !important;
    padding-left: 2px !important;
  }

  .AddEditButton {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  .addbuttondropdown {
    margin-right: 2px !important;
  }

  .flexbasic h3 {
    padding-right: 0px !important;
    padding-left: 2px !important;
  }

  .Tags_Edit {
    width: 80%;
  }

  .collateral-details-pending-responisve {
    padding-left: 0rem !important;
    padding-right: 0 !important;
  }

  .switch-chart {
    display: block !important;
  }

  .merchandise-libarye h4 {
    width: 199px;
    font-size: 15px;

  }

  .merchandise-libarye {
    cursor: pointer;
    margin-right: 48px;
  }

  .collaterals-labe h4 {
    width: 149px;
    font-size: 15px;
  }
}

@media screen and (max-width:490px) {
  .renewbuylogo img {
    height: 34px;
  }

  .logoimage {
    display: block;
    width: 29px;
    padding-right: 10px;
  }

  .renewbuylogo {
    height: 42px;
    margin-left: 18px;
    margin-right: 48px;
  }

  .profile h3 {
    font-size: 16px;
  }

  .profile h5 {
    font-size: 10px;
  }

  .add-collateral-input input {
    width: 308px;
  }
}

@media screen and (max-width:450px) {
  .renewbuylogo {
    height: 42px;
    margin-left: 10px;
    margin-right: 40px;
  }

  .profile h5 {
    font-size: 9px;
    letter-spacing: 0.2px;
  }

  .switchbutton button {
    width: 60px;
    height: 36px;
    border: none;
    color: #535353;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-size: 14px;
  }

  .masterpagination {
    margin-top: 20px;
    justify-content: start;

  }

  #dropdown-basic-button {
    background: none;
    border: 1px solid #FF943D;
    color: #FF943D;
    border-radius: 10px;
    padding: 1px 11px;
  }

  #dropdown-basic-button {
    background: none;
    border: 1px solid #FF943D;
    color: #FF943D;
    border-radius: 10px;
    padding: 5px 11px;
  }

  .switchbutton button {
    width: 60px;
    height: 36px;
    border: none;
    color: #535353;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-size: 14px;
  }

}

@media screen and (max-width:425px) {
  .product-servicex {
    border-bottom: 2px solid #f0f4f8;
    width: 100%;
    padding-left: 0px;
    padding-top: 0px;
  }

  .merchandise-libarye {
    cursor: pointer;
    margin-right: 41px;
  }

  .profiler {
    padding-left: 0px;
  }

  .pagination {
    margin-right: 10px !important;
    margin-left: 0px !important;
    width: 34%;
  }

  .renewpading {
    padding-left: 0px !important;
  }

  .product-service {
    border-bottom: 2px solid #f0f4f8;
    width: 100%;
    padding-left: 0px;
  }

  .paracollteral {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .appdecline {
    margin-right: 15px !important;
  }

  .marleft {
    padding-left: 1rem !important;
  }

  .save-btn-modal {
    /* / margin-top: 15px; / */
    margin-left: 10px;
    width: 100%;
  }

  .concel-btn-modal {
    width: 100%;
    /* / margin-top: 15px; / */
  }

  /* .Tags_Edit {
  width: 100%;
} */
  .activitymonitor {
    margin-right: 2rem !important;
    font-size: 15px;
  }

  .excle-export {
    padding: 7px 13px;
  }

  .merchandise-libarye h4 {
    width: 185px;
    font-size: 14px;

  }

  .merchandise-libarye {
    cursor: pointer;
    margin-right: 35px;
  }

  .collaterals-labe h4 {
    width: 146px;
    font-size: 14px;
  }
}

@media screen and (max-width:390px) {
  .merchandise-libarye {
    cursor: pointer;
    margin-right: 25px;
  }

  .profile h5 {
    font-size: 8px;
    letter-spacing: 0.2px;
  }

  .active-content h3 {
    padding-right: 1px !important;
    padding-left: 3px !important;
  }

  .AddEditButton {
    display: flex;
    align-items: center;
    padding-left: 16px;
  }

  .addbuttondropdown {
    margin-right: 2px !important;
  }

  .flexbasic h3 {
    padding-right: 1px !important;
    padding-left: 3px !important;
  }

  .Tags_Edit {
    width: 100%;
  }

  #dropdown-basic-button {
    margin-left: 0px;
  }

  .searchInput {
    padding: 8px 6px 8px 8px;
  }

  .switchbutton button {
    width: 53px;
    height: 36px;
    border: none;
    color: #535353;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-size: 12px;
  }

  .switchbuttons button {

    width: 66px;
    height: 36px;
    border: none;
    color: #535353;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-size: 14px;


  }

  .pagination {
    margin-right: 10px !important;
    margin-left: 0px !important;
    width: 37%;
  }

  .merchandise-libary {
    cursor: pointer;
    margin-right: 50px;
  }
}

@media screen and (max-width:375px) {
  .product-servicex {
    border-bottom: 2px solid #f0f4f8;
    width: 94%;
    padding-left: 0px;
    padding-top: 18px;
  }

  .merchandise-libarye {
    cursor: pointer;
    margin-right: 38px;
  }

  .merchandise-libarye h4 {
    width: 155px;
    height: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.04em;
    /* / color: #282828; / */

  }

  .collaterals-labe h4 {
    width: 130px;
    height: 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    letter-spacing: 0.04em;
    /* / color: #282828; / */

  }

  h1,
  h2,
  h3 {
    font-size: 18px;
  }

  .AddEditButton {
    display: flex;
    align-items: center;
    padding-left: 8px;
  }

  #dropdown-basic-button {
    background: none;
    border: 1px solid #FF943D;
    color: #FF943D;
    border-radius: 10px;
    padding: 2px 9px;
  }

  .edit_btn_content_hub {
    background: none;
    border: 1px solid #FF943D;
    color: #FF943D;
    border-radius: 10px;
    font-size: 15px;
    padding: 2px 9px;
  }

  .renewbuylogo {
    height: 42px;
    margin-left: 10px;
    margin-right: 32px;
  }

  .add-collateral-input input {
    width: 280px;
  }

  .searchInput {
    padding: 8px 0px 8px 8px;
  }

  .merchandise-libary {
    cursor: pointer;
    margin-right: 26px;
  }

  .renewbuylogo img {
    height: 32px;
  }

  .addCollateralSwitch {
    margin-left: 14px;
  }

  .switchbutton button {
    width: 53px;
    height: 36px;
    border: none;
    color: #535353;
    background-color: #FFFFFF;
    border-radius: 8px;
    font-size: 12px;
  }

  .activitymonitor {
    justify-content: space-between;
    margin-right: 1rem !important;
    font-size: 15px;
    width: 198px;
  }

  .activitymonitor {
    justify-content: space-between;
    margin-right: 1rem !important;
    font-size: 15px;
    width: 126px;
  }

  .excle-export {
    padding: 7px 10px;
  }
}

@media screen and (max-width:355px) {

  .switchtwouton {
    margin-right: 0.5rem !important;
  }

  .pagination {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 39%;
  }

}

@media screen and (max-width:347px) {
  .pagination {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 40%;
  }

  .activitymonitor {
    justify-content: space-between;
    margin-right: 1rem !important;
    font-size: 15px;
    width: 128px;
  }

  .excle-export {
    padding: 7px 3px;
  }
}

@media screen and (max-width:320px) {

  .mainWrapper {
    padding: 0 10px;
  }

  .profiler {
    padding-left: 5px;
  }

  .product-servicex {
    border-bottom: 2px solid #f0f4f8;
    width: 94%;
    padding-left: 0px;
    padding-top: 18px;
  }

  .merchandise-libarye h4 {
    /* / width: 145px; / */
    height: 14px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.04em;
    /* / color: #282828; / */

  }

  .merchandise-libarye {
    cursor: pointer;
    margin-right: 17px;
  }

  .collaterals-labe h4 {
    /* / width: 118px; / */
    height: 17px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 17px;
    letter-spacing: 0.04em;
    /* / color: #282828; / */

  }

  .renewbuylogo img {
    height: 27px;
  }

  .profile h3 {
    width: 100%;
    height: 24px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    letter-spacing: 0.04em;
    color: #282828;
    padding-bottom: 0px;
  }

  .profile h5 {
    width: 100%;
    height: 20px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 6px;
    line-height: 0px;
    letter-spacing: 0.04em;
    color: #747577;
    padding-top: 4px;
  }

  #dropdown-basic-button {
    background: none;
    border: 1px solid #FF943D;
    color: #FF943D;
    border-radius: 10px;
    padding: 2px 6px;
  }

  .add-collateral-input input {
    width: 261px;
  }

  .searchInput {
    padding: 8px 1px 8px 20px;
  }

  h1,
  h2,
  h3 {
    font-size: 15px;
  }

  .pagination {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 40%;
  }
}